@import url('https://fonts.googleapis.com/css?family=Oxygen');


body{
    font-family: 'Oxygen', sans-serif !important;
    primary-color: rgba(63, 81, 181,1);
}

/*h1,h2,h3,h4,h5,h6*/

/*
.ant-btn-primary {
    color: #fff;
    background-color: rgba(63, 81, 181,1);
    border-color: rgba(63, 81, 181,1);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:hover,.ant-btn-primary:focus{
    background-color: rgba(63, 81, 181,1);
    border-color: rgba(63, 81, 181,1);
}
*/
/*
.ant-btn-sm ,.ant-btn-sm:hover, .ant-btn-sm:focus {
    color: rgba(63, 81, 181,1);
    background-color: #fff;
    border-color: rgba(63, 81, 181,1);
}*/
