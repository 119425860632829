@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import "assets/css/bootstrap.min.css";
@import "assets/css/responsive.css";
@import "assets/css/style.css";

*,
body {
    font-family: Helvetica;
}

.ant-form-item {
    margin-bottom: 0;
}

.country-list {
    text-align: left;
}

#phone-form-control {
    width: 100% !important;
}

.dashboard-main-buttons a {
    border-radius: 50px !important;
    margin: 40px auto !important;
}

.ant-input-number-handler-wrap {
    display: none;
}

.disabled {
    cursor: not-allowed;
}

.cancel-button {
    border-radius: 55px !important;
    width: 100px !important;
    margin-top: 0px !important;
}

a.no-hover {
    background-color: #4C6EF8 !important;
    cursor: auto;
}

.mg-top-50 {
    margin-top: 50px;
}

.mg-btm-100 {
    margin-bottom: 100px;
}

.mg-btm-10 {
    margin-bottom: 10px;
}

.mg-btm-50 {
    margin-bottom: 50px;
}

.mg-bottom-10 {
    margin-bottom: 10px !important;
}

.mg-bottom-30 {
    margin-bottom: 30px !important;
}

.text-center-align {
    text-align: center;
}

.all-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio-amount-input .ant-radio-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tac-card {
    float: none !important;
    margin: 0 !important;
    text-align: justify !important;
}

.navbar {
    background-color: white;
    border-bottom: 1px solid #eaeaea;
    height: 60px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #3366ff;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .profile-pic img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 60px;
    left: -300px;
    width: 350px;
    background-color: white;
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 3;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .dropdown-menu.active {
    display: block;
  }
  
  .dropdown-header {
    padding: 15px;
    border-bottom: 1px solid #eaeaea;
}

.dropdown-profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px; 
    flex-shrink: 0; 
}

.dropdown-profile-info {
    display: flex;
    flex-direction: column; 
    color: black;
    flex-grow: 1; 
}

.dropdown-profile-info strong {
    font-size: 1.1rem;
}

.dropdown-profile-info p {
    margin: 0;
    font-size: 0.9rem;
}

  .dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-list li {
    padding: 10px 15px;
    border-bottom: 1px solid #eaeaea;
  }
  
  .dropdown-list li a,
.logout-btn {
  text-decoration: none;
  color: #333;
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}
  .logout-btn {
    color: #ff4d4f;
  }
  
  .logout-btn:hover,
  .dropdown-list li a:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown-list li:last-child {
    border-bottom: none;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .navbar {
      padding: 10px 15px;
    }
  
    .navbar-brand {
      font-size: 1.3rem;
    }
  
    .dropdown-menu {
      top: 60px; 
      left: -250px; 
      width: 300px;
    }
  
    .dropdown-header {
      padding: 10px 15px;
      flex-direction: row; /* Stack profile picture and info on mobile */
      align-items: flex-start; /* Align to the left */
    }
  
    .dropdown-profile-pic {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }
  
    .dropdown-profile-info strong {
      font-size: 1rem;
    }
  
    .dropdown-profile-info p {
      font-size: 0.8rem;
    }
  
    .dropdown-list li {
      padding: 12px 15px;
    }
  
    .logout-btn {
      padding: 12px 15px;
    }
  }  

.register-button {
    cursor: pointer;
    margin-top: 10px;
    color: #214caf;
}

.register-button:hover {
    color: #061f56;
}

.signup-button {
    cursor: pointer;
    margin-top: 10px;
    width: fit-content;
    text-align: center;
    display: block;
    display: inline-block;
    color: #0000FF
}

#forgot-password {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: arial, sans-serif;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

#seconddiv.forgot_pw_div {
    max-height: 100%;
}

#seconddiv {
    max-height: 0;
    transition: 0.5s;
    overflow: hidden;
}

.amount-dtls {
    text-align: center;
    margin: auto;
}

.amount-dtls p input {
    width: 100%;
    margin: 0px 0px 0px;
    padding: 5px 0px;
    float: left;
    background: #fff !important;
}

.amount-dtls p input.readonly-input {
    background-color: #ebeaea !important;
}

/*--------------------------Toggle button-------------------------------*/
.toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    background-color: #4C6EF8;
    border-radius: 55px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 40px auto 0px;
    border: 1px solid #4C6EF8;
    width: 100%;
    max-width: 280px;
}

.label {
    margin-right: 8px;
}

.toggle-button {
    width: 50px;
    height: 25px;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    position: relative;
    transition: background-color 0.3s ease;
}

.toggle-button.active {
    background-color: #069;
}

.toggle-button::after {
    content: '';
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease;
}

.toggle-button.active::after {
    transform: translateX(25px);
}


/*--------------------------FAQ Toggle-------------------------------*/

.faq-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.accordion-item {
  border: 1px solid #ddd!important;
  border-radius: 20px !important;
  overflow: hidden; 
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.accordion-title {
  background-color: #fff;
  padding: 10px 40px 10px 15px;
  cursor: pointer;
  position: relative;
  text-align: left;
}

.accordion-content {
  padding: 15px;
  background-color: #f4f4f4;
  text-align: left;
}


.accordion-title img {
    width: 15px;
    max-width: 100%;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

/*--------------------------Profile-------------------------------*/

.profile-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    border-radius: 55px;
    font-weight: 500;
    font-size: 14px;
    margin: 0px auto 0px;
    width: 100%;
    max-width: 280px;
}

.profile-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;

}

.profile-info {
    flex-grow: 1;
    text-align: left;
}

/*--------------------------Transaction History-------------------------------*/
.history-date {
    display: flex;
    align-items: left;
    justify-content: left;
    width: 100%;
    max-width: 600px;
    margin: 0px auto;
    margin-bottom: 15px;
    font-size: 18px;
    padding: 10px 10px 0px;
    font-weight: bold;
    color: black;
}

.history-container {
    display: flex;
    width: 100%;
    max-width: 600px;
    border-bottom: 1px solid #f4f4f4f4;
    margin: 0px auto;
    justify-content: space-between;
}

.history-left {
    padding: 10px;
    font-size: 25px;
    text-align: left;
}

.history-right {
    padding: 10px;
    font-size: 20px;
    text-align: right;
}

p {
    margin: 0;
}

@media (max-width: 500px) {
    .history-date {
        max-width: 400px;
        margin: 0px auto 0px;
        font-size: 16px
    }

    .history-container {
        max-width: 100%;
    }

    .history-left {
        font-size: 16px
    }

    .history-right {
        font-size: 16px
    }
}

.filter-container {
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 0px auto;
    margin-bottom: -20px;
    justify-content: space-between;
    align-items: center;
}

.filter-date,
.filter-status {
    flex: 45%;
    padding: 10px;
    font-size: 20px;
}

@media (max-width: 500px) {
    .filter-container {
        align-items: stretch;
        margin-bottom: -30px;
    }

    .filter-date,
    .filter-status {
        flex: 1;
        margin-bottom: 10px;
    }
}

.payment-box {
    display: flex;
    justify-content: in-between;
}

/*--------------------------Pick Up Payment-------------------------------*/

.content-box {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border: 1px solid #4C6EF8;
    border-radius: 30px;
    margin-top: 20px;
}

.tab-container {
    display: flex;
    background-color: #f5f7fa;
    border-radius: 25px;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto; 
  }
  
  .function-btns {
    flex: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: #333;
    border-radius: 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .function-btns.active {
    background-color: white;
    color: #4C6EF8; 
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .function-btns:not(.active):hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

.QR-btn {
    width:90%;
    height: auto;
    margin-bottom: 0;
}

@media (max-width: 600px) {
    .content-box {
        width: 100%;
        height: 300px;
    }

    .QR-btn {
        width: 80%;
    }

    .signature-pad {
        width: 350px;
        height: 200px;
    }
}

.signature-pad {
    border: 1px solid black;
    width: 100%;
    height: 200px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

canvas {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*--------------------------Pick Up Payment-------------------------------*/
.pickup-container {
    display: flex;
    flex-direction: row;
    height: 500px;
    margin: 0;
    padding: 0;
  }
  
.flex-item {
    flex: 1;
    padding: 20px;
    min-width: 50%;
  }

.flex-item.map-container {
    position: 'relative';
    padding: 0;
}

#map {
    height: 100%;
    width: 100%;
    min-height: 400px;
}

.location-info {
    max-width: 600px;
    border: 1px solid #4C6EF8;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0px;
}

.directions-select{
    color: #4C6EF8;
    background-color: white;
    border: 1px solid #4C6EF8;
    border-radius: 40px;
    padding: 5px 20px;
    margin: 10px 10px;
    width: 150px;
    font-size: 15px;
}

.directions-select:hover{
    color: white;
    background-color: #4C6EF8;
    border: 1px solid white;
}

@media (max-width: 768px) {
    .pickup-container {
        flex-direction: column-reverse;
        height: auto;
      }
    .flex-item {
        min-width: auto;
        width: 100%;
      }
    #map {
        height: 400px;
    }
    .directions-select{
        width: 120px;
    }
}
/*---------------------------Select Location------------------------*/
.info-container {
    max-width: 600px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
  }
  
  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    color: #666;
  }
  
  .amount {
    font-weight: bold;
    font-size: 18px;
    color: #333;
  }
  
  .divider {
    border-top: 1px solid #ddd;
    margin: 10px 0;
  }
  
  .info-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #888;
  }
  
  .merchant-info {
    display: flex;
    align-items: center;
    gap: 8px; 
  }
  
  .location-icon {
    width: 16px;
    height: auto;
    vertical-align: middle;
  }
  
  .address {
    margin: 0;
    font-weight: bold;
    color: #333;
    padding-left: 0px;
  }
  
/*--------------------------Dashboard-------------------------------*/
.dashboard-container {
    text-align: center;
    padding: 0 15px;
    position: relative;
    top: -70px;
  }
  
  .display-4 {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
  }

  .buttons-container{
    display:flex;
    flex-direction: row;
    margin: 10px;
  }
  
  .buttons-container.btn {
    border: 1px solid #4C6EF8;
    padding: 12px 20px;
    font-size: 16px;
    color: #4C6EF8;
    width: 200px; 
    border-radius: 30px;
    margin-bottom: 10px;
  }
  
  .buttons-container.btn:hover {
    background-color: #4C6EF8;
    border-radius: 30px;
    color: white;
  }
  
  @media (max-width: 599px) {
    .buttons-container {
      flex-direction: column;
      align-items: center;
    }
  }
/*----------------------Payment Choices--------------------*/
.payment-container {
  background-color: #fff;
  padding: 20px;
  margin-top: -70px;
}

.payment-option {
  width: 100%;
  max-width: 500px;
  border-radius: 30px;
  text-align: center;
}

.payment-option.btn {
  border: 1px solid #4C6EF8;
  border-radius: 50px;
  padding: 12px 20px;
  font-size: 16px;
  color: #4C6EF8;
}

.payment-option.btn:hover {
  background-color: #4C6EF8;
  color: white;
}

.text-center h2 {
  font-weight: 500;
  font-size: 24px;
  color: #333;
  margin-top: 20px;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 10px;
  }

  .text-center h2 {
    font-size: 20px;
  }

  .payment-option {
    max-width: 100%;
    font-size: 14px;
  }

  .payment-option.btn {
    padding: 10px 16px;
  }

  button {
    left: -10px; /* Adjust the back button position for smaller screens */
  }
}

/*---------------------Card Details---------------------*/

.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  margin-top: 40px;
}

.card-container {
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  max-width: 600px;
  width: 100%; 
  background-color: #fff;
  margin-top: 20px;
}

.card-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 10px 10px
}

.card-details {
  font-size: 12px;
  font-weight: 300;
  color: black;
  margin: 0;
}

.deposit-box {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.flex-label {
  font-size: 14px;
  font-weight: 300;
  color: black;
  margin-bottom: 5px;
  background-color: white;
}

.readonly-input {
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  width: 100px;
}

.readonly-input:focus,
.readonly-input:hover {
    outline: none;
    border: 1px solid #e8e8e8;
    background-color: white;
}

@media (max-width: 768px) {
  .card-container {
    width: 100%;
    }
}

/*--------------------------Help & FAQ-------------------------------*/
.help-container {
  text-align: center;
  padding: 0 15px;
  margin-top: -70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.help-button{
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.help-button.btn {
  border: 1px solid #4C6EF8;
  padding: 12px 20px;
  font-size: 16px;
  color: #4C6EF8;
  width: 600px; 
  border-radius: 30px;
  margin-bottom: 10px;
}

.help-button.btn:hover {
  background-color: #4C6EF8;
  border-radius: 30px;
  color: white;
}

@media (max-width: 768px) {
  .help-button.btn {
    width: 100%;
  }
}

.chat-container {
  position: fixed;
  margin-top:60px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999; 
}

.exit-chat-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000; 
  padding: 10px 20px; 
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; 
  max-width: 200px;
  display: inline-block; 
}

@media (max-width: 768px) {
  .exit-chat-button {
    font-size: 14px !important; 
    top: 10px !important; 
    margin-left: calc(100% - 104px);
    margin-right: 5px;
  }
}

.chat-iframe {
  border: none;
  width: 100%;
  height: 100%;
}

/*---------------------Account Set Up-----------------------*/
.signup-container {
  display: flex;
  justify-content: center; /* Horizontally center the container */
  align-items: center; /* Vertically center the container */
  height: 100vh; /* Full viewport height to center vertically */
  padding: 20px;
}

.progress-bar {
  margin-right: 20px;
  width: 30%;
}

.stepper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 16px;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
}

.circle.active {
  background-color: #4a90e2;
  color: white;
}

/* Using a pseudo-element to create the line */
.step::before {
  content: '';
  width: 2px;
  height: 100%;
  background-color: #e0e0e0;
  position: absolute;
  left: 14px; 
  top: 40px;
  z-index: 0;
}

.step.active::before {
  background-color: #4a90e2;
}

.step:last-child::before {
  display: none;
}

.step-info {
  margin-left: 20px;
  text-align: left;
}

.step-info h4 {
  margin: 0;
  font-size: 14px;
}

.step-info p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.step-content {
  width: 60%;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .signup-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 120px);
    padding: 10px;
  }

  .progress-bar {
    display: block;
    position: fixed;
    top: 80px;
    width: 100%;
    background-color: white;
    padding: 10px;
    z-index: 1000;
    margin: 0;
  }

  .stepper {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
  }

  .step-info {
    display: block;
    text-align: center;
    margin-top: 5px;
    margin-left: 0;
    font-size: 12px;
  }

  .step-info h4{
    display: none;
  }

  .step-info p{
    display: none;
  }

  .step-info[data-mobile-text]:after {
    content: attr(data-mobile-text);
  }

  .step {
    display: flex;
    align-items: center;
    margin-bottom: 0; 
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .circle {
    width: 50px;
    height: 50px;
    font-size: 20px;
    margin: 10px;
  }

  .circle.active {
    background-color: #4a90e2;
    color: white;
  }

  .step::before {
    display: none;
  }

  .step-content {
    width: 90%;
    padding-left: 0;
    margin-top: 200px;
  }
}

/*--------------------Account Set-up Step 3------------------*/
.camera-container {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.video-feed, .captured-image{
  width: 300px; 
  height: 300px;
  border-radius: 50%; 
  object-fit: cover; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transform: scaleX(-1);
}

.capture-btn {
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4C6EF8;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .video-feed, .captured-image {
    max-width: 90%;
  }
}

/*---------------------Account Setup Step 4-----------------*/

.otp-inputs{
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.otp-input{
  width: 60px;
  height: 60px;
  margin: 0 10px;
  text-align: center;
  font-size: 32px;
  border: 2px solid #d0d0d0;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
}

.otp-input:focus {
  border-color: #4C6EF8;
}

/*---------------------UNIVERSAL---------------------*/
.universal-btn{
    color: white !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    background-color: #4C6EF8 !important;
    border: none !important;
    border-radius: 40px !important;
    padding: 10px 20px !important;
    margin: 20px 20px !important;
    width: 150px !important;
}

.ant-form-item-label {
  margin-bottom: -13px !important;
}

.ant-form-item-control-wrapper {
  margin-top: 0px !important;
}

@media (max-width: 768px) {
  .ant-upload-list-item-name {
    max-width: 120px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
    vertical-align: middle !important;
  }

  .ant-upload-list-item {
    max-width: 150px !important;
  }

  .ant-upload-list-item-thumbnail {
    max-width: 50px !important;
    height: auto;
  }
}