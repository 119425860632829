@media only screen and ( min-width:320px) and ( max-width:599px ){

.card-dtls form label{ font-size: 14px;}

.spare-dvs h1{font-size:22px;}

.spare-dvs h2{font-size:20px !important;}

.spare-dvs h3{font-size:18px !important;}

.spare-dvs h4{font-size:16px !important;}

.card-dtls h3{font-size: 20px;}

.cash-dvs h1{font-size: 32px;}

.cash-dvs p{font-size: 15px;}

.ntwrk-dvs h1{font-size: 32px;}

.email-dvs form .btn-enter input {
padding: 16px 0px;}

.send-btn{   padding: 16px 0px;}

.lctn-dvs a{  padding: 16px 0px;}

.cshrdy-dv .btn-cash input{ padding: 16px 0px;}

.spare-dvs a{ padding: 16px 0px;}

.terms-dv a{ padding: 16px 0px;}

.fund-bt{ padding: 16px 0px;}

.responsive-image{
    width: 100%;
    height: auto;
}

}
